import { Box, styled, BoxProps } from "@mui/material";
import React, { useEffect, forwardRef, useRef, useState, useCallback, useImperativeHandle } from "react";
import { debounce } from "@mui/material/utils";

export const SCROLLBAR_SIZE = 10;
export const SCROLLBAR_MARGIN = 15;

interface ScrollableContainerProps extends BoxProps {
  hasScrollbar?: boolean;
}

const ScrollableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasScrollbar",
})<ScrollableContainerProps>(({ theme, hasScrollbar }) => ({
  overflowY: "auto",
  paddingRight: hasScrollbar ? `${SCROLLBAR_SIZE}px` : undefined,
  marginRight: hasScrollbar ? `-${SCROLLBAR_MARGIN}px` : "0",
}));

const Scrollable = forwardRef<HTMLDivElement, BoxProps>(({ children, ...rest }: BoxProps, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  const updateScrollbarState = useCallback(() => {
    if (containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      setHasScrollbar(scrollHeight > clientHeight);
    }
  }, []);

  useImperativeHandle(ref, () => containerRef.current as HTMLDivElement);

  useEffect(() => {
    updateScrollbarState(); // Check on mount

    const debouncedUpdate = debounce(updateScrollbarState, 200);
    window.addEventListener("resize", debouncedUpdate); // Check on resize
    return () => {
      window.removeEventListener("resize", debouncedUpdate);
      debouncedUpdate.clear();
    };
  }, [updateScrollbarState]);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!ref) return;
      const container = (ref as React.RefObject<HTMLDivElement>).current;
      if (!container) return;
      const distanceX = container.offsetLeft + container.offsetWidth - e.pageX;
      const distanceY = container.offsetTop + container.offsetHeight - e.pageY;
      if ((distanceX < 15 && distanceX > -15) || (distanceY < 15 && distanceY > -15)) {
        container.classList.add("expanded");
      } else {
        container.classList.remove("expanded");
      }
    };

    window.addEventListener("mousemove", listener);
    return () => {
      window.removeEventListener("mousemove", listener);
    };
  }, [ref]);

  useEffect(() => {
    updateScrollbarState();
  }, [children, updateScrollbarState]);

  return (
    <ScrollableContainer ref={containerRef} hasScrollbar={hasScrollbar} {...rest}>
      {children}
    </ScrollableContainer>
  );
});

Scrollable.displayName = "Scrollable";

export default Scrollable;
