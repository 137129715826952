export const ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT = `
  fragment IceHockeyLiveDataTypeFragment on IceHockeyLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    awayTeamGoalsByPeriod
    homeTeamGoalsByPeriod
    # awayTeamPenalityMinuteByPeriod
    # homeTeamPenalityMinuteByPeriod
    # awayTeamPenalityCountByPeriod
    # homeTeamPenalityCountByPeriod
    # awayTeamPenalityShotByPeriod
    # homeTeamPenalityShotByPeriod
  }
`;

export type IceHockeyLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  awayTeamGoalsByPeriod: number[] | null;
  homeTeamGoalsByPeriod: number[] | null;
  // awayTeamPenalityMinuteByPeriod: number[];
  // homeTeamPenalityMinuteByPeriod: number[];
  // awayTeamPenalityCountByPeriod: number[];
  // homeTeamPenalityCountByPeriod: number[];
  // awayTeamPenalityShotByPeriod: number[];
  // homeTeamPenalityShotByPeriod: number[];
};